import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SimpleResultPage from './pages/SimpleResultPage';
import NoticeModal from './modals/NoticeModal';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/SimpleResultPage" element={<SimpleResultPage />} />
        <Route path="/notice" element={<NoticeModal />} />
      </Routes>
    </Router>
  );
}

export default App;
