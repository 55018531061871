class Constants {
    static graders() 
    {
        return [ 
            { value: 'PSA', label: 'PSA' },
            { value: 'BGS', label: 'BGS' },
            { value: 'CGC', label: 'CGC' }
         ];
    }

    static conditions()
    {
        return [ 
            { value: 'GM', label: 'Gem Mint' },
            { value: 'NM', label: 'Near Mint' },
            { value: 'LP', label: 'Light Play' },
            { value: 'MP', label: 'Mild Play' },
            { value: 'HP', label: 'Heavy Play' },
            { value: 'DMG', label: 'Damaged' },
            { value: '10', label: 'Graded 10' },
            { value: '9', label: 'Graded 9' },
            { value: '8', label: 'Graded 8' },
            { value: '7', label: 'Graded 7' }
        ];
    }

    static sets() 
    {
        return [
            { value: 'BS1', label: 'Base Set 1st Edition' },
            { value: 'BSS', label: 'Base Set Shadowless' },
            { value: 'BSU', label: 'Base Set Unlimited' },
            { value: 'BS2', label: 'Base Set 2' },
            { value: 'JU1', label: 'Jungle 1st Edition' },
        ];
    }

    static pokemonNames() 
    {
        return [
            'Bulbasaur', 'Ivysaur', 'Venusaur', 
            'Charmander', 'Charmeleon', 'Charizard', 
            'Squirtle', 'Wartortle', 'Blastoise',
            'Caterpie', 'Metapod', 'Butterfree',
            'Weedle', 'Kakuna', 'Beedrill',
            'Pidgey', 'Pidgeotto', 'Pidgeot',
            'Rattata', 'Raticate',
            'Spearow', 'Fearow',
            'Ekans', 'Arbok',
            'Pikachu', 'Raichu',
            'Sandshrew', 'Sandslash',
            'Nidoran (F)', 'Nidorina', 'Nidoqueen',
            'Nidoran (M)', 'Nidorino', 'Nidoking',
            'Clefairy', 'Clefable',
            'Vulpix', 'Ninetales',
            'Jigglypuff', 'Wigglytuff',
            'Zubat', 'Golbat',
            'Oddish', 'Gloom', 'Vileplume',
            'Paras', 'Parasect',
            'Venonat', 'Venomoth',
            'Diglett', 'Dugtrio',
            'Meowth', 'Persian',
            'Psyduck', 'Golduck',
            'Mankey', 'Primeape',
            'Growlithe', 'Arcanine',
            'Poliwag', 'Poliwhirl', 'Poliwrath',
            'Abra', 'Kadabra', 'Alakazam',
            'Machop', 'Machoke', 'Machamp',
            'Bellsprout', 'Weepinbell', 'Victreebel',
            'Tentacool', 'Tentacruel',
            'Geodude', 'Graveler', 'Golem',
            'Ponyta', 'Rapidash',
            'Slowpoke', 'Slowbrow',
            'Magnemite', 'Magneton',
            'Farfetchd', 
            'Doduo', 'Dodrio',
            'Seel', 'Dewgong',
            'Grimer', 'Muk',
            'Shellder', 'Cloyster',
            'Gastly', 'Haunter', 'Gengar',
            'Onix',
            'Drowzee', 'Hypno',
            'Krabby', 'Kingler',
            'Voltorb', 'Electrode',
            'Exeggcute', 'Exeggutor',
            'Cubone', 'Marowak',
            'Hitmonlee', 'Hitmonchan',
            'Lickitung',
            'Koffing', 'Weezing',
            'Rhyhorn', 'Rhydon',
            'Chansey', 'Tangela', 'Kangaskhan',
            'Horsea', 'Seadra',
            'Goldeen', 'Seaking',
            'Staryu', 'Starmie',
            'Mr. Mime', 'Scyther', 'Jynx',
            'Electabuzz', 'Magmar', 'Pinsir',
            'Tauros', 'Magikarp', 'Gyarados',
            'Lapras', 'Ditto', 'Eevee',
            'Vaporeon', 'Jolteon', 'Flareon',
            'Porygon', 'Omanyte', 'Omastar',
            'Kabuto', 'Kabutops', 'Aerodactyl',
            'Snorlax',
            'Articuno', 'Zapdos', 'Moltres',
            'Dratini', 'Dragonair', 'Dragonite',
            'Mewtwo', 'Mew'
        ]
    }
}

export default Constants;