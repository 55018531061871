import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Footer.css'

const Footer = () => {
  const navigate = useNavigate();

  const openModal = () => {
    navigate('/notice');
  };

  return (
    <footer>
      <p id="termsandconditions">
        <button id='copyrightButton' onClick={openModal}>© {new Date().getFullYear()} TCGPriceCheck.com. Terms & Conditions.</button>
      </p>
    </footer>
  );
};

export default Footer;
