// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../content/BrickWall.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.main-content {
  display: flex;
  position: fixed;
  width: 100%;
  max-width: 750px;
  justify-content: center;
}

.background {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: static;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/HomePage.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,yDAAiD;EACjD,uBAAuB;EACvB,4BAA4B;EAC5B,aAAa;EACb,WAAW;AACb","sourcesContent":["\n.main-content-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 20px;\n}\n\n.main-content {\n  display: flex;\n  position: fixed;\n  width: 100%;\n  max-width: 750px;\n  justify-content: center;\n}\n\n.background {\n  background-image: url('../content/BrickWall.png');\n  background-size: static;\n  background-repeat: no-repeat;\n  height: 100vh;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
