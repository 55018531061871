import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoticeModal = () => {
  const navigate = useNavigate();

  const closeModal = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div style={{ position: 'relative', width: '35%', height: '50%', backgroundColor: 'white', padding: '20px', overflowY: 'auto' }}>

      <h2>Terms and Conditions</h2>
      <h3>1. Introduction</h3>
      <p>
        Welcome to TCGPriceCheck.com! This website is owned, operated, maintained and developed by Crimson Planet Software LLC. 
        By using our website, you agree to these Terms and Conditions.
      </p>
      <h3>2. Use of Website</h3>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to access and use our website.
      </p>
      <h3>3. Content</h3>
      <p>
        We are not associated with GameFreak, The Pokémon Company, Nintendo, or any other entities mentioned on this site. Additionally, we do not claim ownership of their respective intellectual properties or assets.
        This website and its use of copyrighted materials are intended for informational purposes only.
      </p>
      <h3>4. User Conduct</h3>
      <p>
        You agree to use our website lawfully and not engage in any activities that are illegal or prohibited by these Terms and Conditions.
      </p>
      <h3>7. Limitation of Liability</h3>
      <p>
        TCGPriceCheck.com is not liable for any damages arising from the use of or inability to use our website. We do not guarantee the accuracy of the sales data available on our site. You assume the risk of making decisions based on any provided information.
      </p>
      <h3>8. Changes to Terms</h3>
      <p>
        We may modify these terms at any time. Continued use of our website indicates acceptance of the new terms.
      </p>
      <h3>9. Governing Law</h3>
      <p>
        These terms are governed by the laws of Minnesota, USA.
      </p>
      <h3>10. Contact Us</h3>
      <p>
        If you have any questions, contact us at crimsonplanetsoftware@gmail.com.
      </p>
      <h3>Privacy Notice</h3>
      <h3>1. Information We Collect</h3>
      <p>
        We do not collect personally identifiable information (PII). We do, however, gather data regarding website usage to enhance functionality. No PII is associated with usage data.
      </p>
        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'red',
            color: 'white',
          }}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default NoticeModal;
