import React from 'react';
import '../css/HomePage.css'
import Footer from '../components/Footer';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';
import CardSearchForm from '../components/CardSearchForm';

const HomePage = () => {
    return (
        <div className="page">
            <div className="background">
                <Header />
                <NavigationBar />
                <div className="main-content-container">
                    <div className="main-content">
                        <CardSearchForm />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}
 
export default HomePage;