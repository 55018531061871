// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    background-color: #333;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

#termsandconditions {
    margin: 0;
    transform: translateY(-5%);
}
  
#copyrightButton {
    background-color: #333;
    color: white;
    border: none;
}

#copyrightButton:hover {
    color: orange;
}`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["footer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 35px;\n    background-color: #333;\n    color: #fff;\n    position: fixed;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 100%;\n    border-top-left-radius: 15px;\n    border-top-right-radius: 15px;\n}\n\n#termsandconditions {\n    margin: 0;\n    transform: translateY(-5%);\n}\n  \n#copyrightButton {\n    background-color: #333;\n    color: white;\n    border: none;\n}\n\n#copyrightButton:hover {\n    color: orange;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
