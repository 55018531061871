import QueryBuilder from "./QueryBuilder";

class ApiClient {
    constructor() 
    {
        this.baseURL = `https://api.tcgpricecheck.com`;

        this.queryBuilder = new QueryBuilder();
    }

    async fetchSalesInformation(name, abbreviation, condition, grader) 
    {
        const queryString = this.queryBuilder.buildSearchQuery(name, abbreviation, condition, grader);

        return await this.fetch(`SalesInformation?${queryString}`);
    }

    async fetch(query)
    {
        try 
        {
            const response = await fetch(`${this.baseURL}/${query}`);

            if (!response.ok) 
            {
                throw new Error(`Bad response while fetching ${query}: ${response.status}`);
            }

            const data = await response.json();
            return data;
        }
        catch (error) 
        {
            console.error('Error fetching data: ', error);
            throw error;
        }
    }
}

export default ApiClient;