import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/NavigationBar.css'

const NavigationBar = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="nav-container">
      <button id='homeButton' onClick={navigateHome}>HOME</button>
    </div>
  );
};

export default NavigationBar;
