import { useState } from 'react';

const Dropdown = ({ type, options, onOptionChange }) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleChange = event => {
        onOptionChange(event.target.value);
        setSelectedOption(event.target.value);
    };

    return (
        <select value={selectedOption} onChange={handleChange} className="form-input">
            <option value="">Select a(n) {type} (Optional)</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Dropdown;
