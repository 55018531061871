class QueryBuilder {
    buildSearchQuery(name, abbreviation, condition, grader, holographic) 
    {
        let query = "";

        if (name != null && name !== '') 
        {
            query += `Name=${name}`;
        }

        if (abbreviation != null && abbreviation !== '') 
        {
            query += `&Abbreviation=${abbreviation}`;
        }

        if (condition != null && condition !== '') 
        {
            if (Number.isInteger(Number(condition))) 
            {
                query += `&Grade=${condition}`;
            } 
            else 
            {
                query += `&Condition=${condition}`;
            }
        }

        if (grader != null && grader !== '')
        {
            query += `&Grader=${grader}`;
        }

        if (holographic != null && holographic !== '')
        {
            query += `&Holographic=${holographic}`;
        }

        return query;
    }
}

export default QueryBuilder;