// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 2px;
    border-radius: 5px;
    transform: translateY(200%);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);
}

button {
    width: 100%;
    margin: 1px 0;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    color: orange;
}

@media (max-width: 1000px) {
    .nav-container {
      display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/NavigationBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,8EAA8E;AAClF;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;MACE,aAAa;IACf;AACJ","sourcesContent":[".nav-container {\n    position: fixed;\n    top: 10px;\n    left: 10px;\n    width: 100px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: #fff;\n    padding: 2px;\n    border-radius: 5px;\n    transform: translateY(200%);\n    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);\n}\n\nbutton {\n    width: 100%;\n    margin: 1px 0;\n    padding: 5px;\n    text-align: center;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\nbutton:hover {\n    color: orange;\n}\n\n@media (max-width: 1000px) {\n    .nav-container {\n      display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
