// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    max-width: 500px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 15px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);
}

.searchform form {
    width: 100%;
}

.searchform label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #666;
    width: 100%; 
}

.searchform input[type="text"] {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.searchform button,
.searchform input[type="submit"] {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    transform: translateX(235%);
}

.searchform button:hover,
.searchform input[type="submit"]:hover {
    background-color: #0056b3;
}

.form-input {
    width: 275px;
    height: 35px;
    text-align: center;
    color: #666;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: medium;
    border-radius: 4px; 
}`, "",{"version":3,"sources":["webpack://./src/css/CardSearchForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,8EAA8E;AAClF;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,2DAA2D;IAC3D,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".searchform {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: 5px;\n    max-width: 500px;\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 15px;\n    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);\n}\n\n.searchform form {\n    width: 100%;\n}\n\n.searchform label {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 10px;\n    color: #666;\n    width: 100%; \n}\n\n.searchform input[type=\"text\"] {\n    flex-grow: 1;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.searchform button,\n.searchform input[type=\"submit\"] {\n    padding: 10px 20px;\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    transition: background-color 0.3s ease;\n    transform: translateX(235%);\n}\n\n.searchform button:hover,\n.searchform input[type=\"submit\"]:hover {\n    background-color: #0056b3;\n}\n\n.form-input {\n    width: 275px;\n    height: 35px;\n    text-align: center;\n    color: #666;\n    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    font-size: medium;\n    border-radius: 4px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
