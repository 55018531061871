import { useState } from 'react';
import '../css/CardSearchForm.css';
import Dropdown from '../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import Constants from '../constants/Constants';

function CardSearchForm() {
  const validPokemonNames = Constants.pokemonNames();

  const [pokemonName, setPokemonName] = useState('Bulbasaur');
  const [pokemonNameAutoCompleted, setPokemonNameAutoCompleted] = useState(false);
  const [expansionValue, handleExpansionChange] = useState(null);
  const [conditionValue, handleConditionChange] = useState(null);
  const [grader, handleGraderChange] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = event => {
    navigate('/SimpleResultPage', { state: { pokemonName: pokemonName, expansionValue: expansionValue, conditionValue: conditionValue, grader: grader }});
  }

  const handlePokemonNameChange = event => {
    const pokemonName = event.target.value;
    setPokemonName(pokemonName);
  
    if (!pokemonName) {
      //setSuggestions([]);
      return;
    }
  
    const newSuggestions = validPokemonNames.filter(option =>
      option.toLowerCase().startsWith(pokemonName.toLowerCase())
    );

    if (pokemonName.length === 1) 
    {
        setPokemonNameAutoCompleted(false);
    }
    else if (newSuggestions.length === 0 && !pokemonNameAutoCompleted) {
      alert(`No Match Found: ${pokemonName}`);
      setPokemonName('');
    } else if (newSuggestions.length === 1 && !pokemonNameAutoCompleted) {
      setPokemonName(newSuggestions[0]);
      setPokemonNameAutoCompleted(true);
    } else {
      //setSuggestions(newSuggestions);
    }
  }

  return (
    <div className="searchform">
      <form onSubmit={handleSubmit}>
        <label>
          <input 
            className="form-input"
            type="text"
            value={pokemonName}
            spellCheck="false"
            onChange={handlePokemonNameChange} 
          />
        </label>
        <label>
          <Dropdown type="Expansion" options={Constants.sets()} onOptionChange={handleExpansionChange} />
        </label>
        <label>
          <Dropdown type="Condition" options={Constants.conditions()} onOptionChange={handleConditionChange} />
        </label>
        <label>
          <Dropdown type='Grader' options={Constants.graders()} onOptionChange={handleGraderChange}/>
        </label>
        <input type="submit" value="Search" />
      </form>
    </div>
  );
}

export default CardSearchForm;