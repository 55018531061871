import React from 'react';
import '../css/Header.css'

const Header = () => {
  return (
    <header id="websiteName">
      <p> TCG Price Check </p>
    </header>
  );
};

export default Header;
