// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #1F2833;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);
  }

#websiteName {
    margin: 0;
    font-size: 35px;
    font-weight: 500;
    transform: translateY(-10%);
}`, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,+BAA+B;IAC/B,gCAAgC;IAChC,8EAA8E;EAChF;;AAEF;IACI,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,2BAA2B;AAC/B","sourcesContent":["header {\n    position: relative;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 50px;\n    background-color: #1F2833;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-sizing: border-box;\n    border-bottom-left-radius: 15px;\n    border-bottom-right-radius: 15px;\n    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19), 0px 5px 5px rgba(0, 0, 0, 0.23);\n  }\n\n#websiteName {\n    margin: 0;\n    font-size: 35px;\n    font-weight: 500;\n    transform: translateY(-10%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
