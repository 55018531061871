import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/SimpleResultPage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import ApiClient from '../clients/ApiClient';

const SimpleResultPage = () => {
    const location = useLocation();
    const name = location.state.pokemonName;
    const abbreviation = location.state.expansionValue;
    const condition = location.state.conditionValue;
    const grader = location.state.grader;

    const [sales, setSales] = useState([]);

    useEffect(() => {
      const client = new ApiClient();

      const fetch = async () => {
          try {
              const result = await client.fetchSalesInformation(name, abbreviation, condition, grader);
              setSales(result);
          } catch (error) {
              throw error;
          }
      };

      fetch();
    }, [abbreviation, condition, grader, name]);

    const formatToUSD = (numberToFormat) =>
    {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      return formatter.format(numberToFormat);
    }
    
    return (
      <div className="page">
        <div className="background">
          <div>
            <Header />
            <NavigationBar />
            <div className="main-content-container">
              {sales ? (
                <div className="content-simple-result-page">
                  <div class='hide-on-small' id='card-search-form'>
                    
                  </div>
                  <div className='table-container'>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Sale Price</th>
                          <th>Sale Date</th>
                          <th class='hide-on-small'>Condition</th>
                          <th class='hide-on-small'>Grader</th>
                          <th>Grade</th>
                          <th class='hide-on-small'>Expansion</th>
                          <th class='hide-on-small'>Signature</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sales.map((item, index) => {
                          let backgroundColor = item.accuracy < 0 ? '#ff9c9c' : null;
                          let priceInUSD = formatToUSD(item.price);
                          let saleDate = item.date;

                          return (
                            <tr key={index} id={item.key} accuracy={item.accuracy} style={{ backgroundColor: backgroundColor }}>
                              <td>{item.cardName}</td>
                              <td>{priceInUSD}</td>
                              <td>{saleDate}</td>
                              <td class='hide-on-small'>{item.condition === 'UKN' ? 'Unknown' : item.condition}</td>
                              <td class='hide-on-small'>{item.grader}</td>
                              <td>{item.grade === 0 ? 'Ungraded' : item.grade}</td>
                              <td class='hide-on-small'>{item.abbreviation}</td>
                              <td class='hide-on-small'>
                                <button onClick={() => window.open(`https://www.ebay.com/itm/${item.referenceNumber}`, '_blank')}> {item.signature} </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                'Loading...'
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}
 
export default SimpleResultPage